import { API_BASE_URL } from 'environment';

export async function getAllUnitAPI(searchParams) {
  var requestOptions = { method: 'GET', redirect: 'follow' };

  return fetch(`${API_BASE_URL}/api/unit${searchParams ?? ''}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => err);
}

export async function getUnitAPI(id, token) {
  var myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  var requestOptions = { method: 'GET', redirect: 'follow', headers: myHeaders };

  return fetch(`${API_BASE_URL}/api/unit/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => err);
}

export async function getAllUnitTypeAPI(searchParams) {
  var requestOptions = { method: 'GET', redirect: 'follow' };

  return fetch(`${API_BASE_URL}/api/unit-type${searchParams ?? ''}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => err);
}

export async function getUnitInspectAPI(id) {
  var requestOptions = { method: 'GET', redirect: 'follow' };

  return fetch(`${API_BASE_URL}/api/report-inspection/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => err);
}
