import useAppState from 'context/useAppState';
import { toast } from 'react-hot-toast';
import _delay from 'utils/delay';

export default function useFetch() {
  const { openModal, closeModal } = useAppState((state) => state.modal);

  async function fetchAPI(callbackPromise, options = defaultOptions) {
    options = { ...defaultOptions, ...options };

    const { loadingMessage, successMessage, errorMessage, withToast, withLoadingScreen, delay } = options;

    let result;

    if (withToast) {
      result = await toast.promise(fetchCallbackPromise(callbackPromise, withLoadingScreen, delay), {
        error: (err) => errorMessage ?? err,
        success: (res) => successMessage ?? res.message,
        loading: loadingMessage ?? 'loading',
      });
    } else {
      try {
        result = await fetchCallbackPromise(callbackPromise, withLoadingScreen, delay);
      } catch (error) {
        const rejectPromise = () => Promise.reject(error);

        result = await toast.promise(rejectPromise(), {
          error: (err) => errorMessage ?? err,
          success: (res) => successMessage ?? res.message,
          loading: loadingMessage ?? 'loading',
        });
      }
    }

    return result;
  }

  const fetchCallbackPromise = async (callbackPromise, withLoadingScreen, delay) => {
    try {
      if (withLoadingScreen) openModal();

      if (delay) await _delay(delay);

      const result = await callbackPromise();

      if (result.name === 'TypeError') return Promise.reject(result.message);

      if (result.status === false && typeof result.message === 'string') return Promise.reject(result.message);

      if (result.status === false && typeof result.message === 'object')
        return Promise.reject(Object.values(result.message).join('\n'));

      if (result.status === false) return Promise.reject(JSON.stringify(result.message));

      if (result.error) return Promise.reject(result.error.message);

      return Promise.resolve(result);
    } catch (error) {
      Promise.reject(error);
    } finally {
      if (withLoadingScreen) closeModal();
    }
  };

  return fetchAPI;
}

const defaultOptions = {
  delay: 0,
  withToast: true,
  withLoadingScreen: true,
  errorMessage: null,
  successMessage: null,
  loadingMessage: null,
};
