import { useState } from 'react';

export default function alertState() {
  const [isOpen, setOpen] = useState(false);
  const [children, setChildren] = useState('');

  function openAlert(reactNode) {
    setOpen(true);

    setChildren(reactNode);
  }

  function closeAlert() {
    setOpen(false);

    setChildren('');
  }

  return { isOpen, openAlert, closeAlert, children };
}

export const initialAlertState = {
  isOpen: false,
  openAlert: () => alert('alert state: unhandled function'),
  closeAlert: () => alert('alert state: unhandled function'),
  children: '',
};
