import { Suspense, lazy } from 'react';
import { Navigate, Outlet, Route, Routes as Switch } from 'react-router-dom';

import AuthRoute from './AuthRoute';
import ProtectedRoute from './ProtectedRoute';

const RootLayout = lazy(() => import('app/layout'));

// authenthication
const AuthLayout = lazy(() => import('app/auth/layout'));
const LoginPage = lazy(() => import('app/auth/login/page'));
const RegisterPage = lazy(() => import('app/auth/register/page'));
const AccountRegisterPage = lazy(() => import('app/auth/register/account/page'));
const OtherRegisterPage = lazy(() => import('app/auth/register/other/page'));
const VerificationRegisterPage = lazy(() => import('app/auth/register/verification/page'));
const ForgotPasswordPage = lazy(() => import('app/auth/forgot-password/page'));
const EmailCheckPage = lazy(() => import('app/auth/forgot-password/email-check/page'));
const ResetPasswordPage = lazy(() => import('app/auth/forgot-password/reset-password/page'));
const RedirectToResetPasswordPage = lazy(() => import('app/reset-password/[token]/[email]/page'));
const RedirectToLoginPage = lazy(() => import('app/masuk/page'));

// protected
const DepositPage = lazy(() => import('app/deposit/page'));
const TransactionDetailPage = lazy(() => import('app/transaction/[type]/[id]/page'));
const AuctionDetailLivePage = lazy(() => import('app/auction/[id]/live/page'));
const UserLayout = lazy(() => import('app/user/layout'));
const UserSettingsPage = lazy(() => import('app/user/settings/page'));
const UserTransactionPage = lazy(() => import('app/user/transaction/page'));
const UserDepositPage = lazy(() => import('app/user/deposit/page'));
const UserFavoritePage = lazy(() => import('app/user/favorite/page'));
const UserNotificationPage = lazy(() => import('app/user/notification/page'));
const UserProxyBidPage = lazy(() => import('app/user/proxy-bid/page'));

// public
const HomePage = lazy(() => import('app/home/page'));
const UnitPage = lazy(() => import('app/unit/page'));
const UnitDetailPage = lazy(() => import('app/unit/[id]/page'));
const UnitDetailInspectPage = lazy(() => import('app/unit/[id]/inspect/page'));
const AuctionPage = lazy(() => import('app/auction/page'));
const AuctionDetailPage = lazy(() => import('app/auction/[id]/page'));
const ConsignmentSalePage = lazy(() => import('app/consignment-sale/page'));
const ArticlePage = lazy(() => import('app/article/page'));
const ArticleDetailPage = lazy(() => import('app/article/[slug]/page'));
const AboutPage = lazy(() => import('app/about/page'));
const ProcedureLayout = lazy(() => import('app/procedure/layout'));
const ProcedurePage = lazy(() => import('app/procedure/[slug]/page'));
const PrivacyPage = lazy(() => import('app/privacy/page'));
const TermsPage = lazy(() => import('app/terms/page'));
const LocationPage = lazy(() => import('app/location/page'));
const FAQPage = lazy(() => import('app/faq/page'));
const ContactPage = lazy(() => import('app/contact/page'));
const PrivacyNoticePage = lazy(() => import('app/privacy-notice/page'));

export default function RouteList() {
  return (
    <Switch>
      <Route>
        <Route element={<Navigate replace to="/auth/login" />} path="login" />
        <Route element={<Navigate replace to="/auth/register" />} path="register" />
        <Route element={<Navigate replace to="/auth/forgot-password" />} path="forgot-password" />
        <Route element={<RedirectToResetPasswordPage />} path="reset-password/:token/:email" />
        <Route element={<RedirectToLoginPage />} path="masuk" />
        <Route element={<AuthRoute />} path="auth">
          <Route element={<AuthLayout />}>
            <Route element={<LoginPage />} path="login" />
            <Route element={<RegisterPage />} path="register" />
            <Route element={<AccountRegisterPage />} path="register/account" />
            <Route element={<OtherRegisterPage />} path="register/other" />
            <Route element={<VerificationRegisterPage />} path="register/verification" />
            <Route element={<ForgotPasswordPage />} path="forgot-password" />
            <Route element={<EmailCheckPage />} path="forgot-password/email-check" />
            <Route element={<ResetPasswordPage />} path="forgot-password/reset-password" />
          </Route>
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route element={<TransactionDetailPage />} path="transaction/:type/:id/print" />
          <Route element={<RootLayout />}>
            <Route element={<DepositPage />} path="topup" />
            <Route element={<TransactionDetailPage />} path="transaction/:type/:id" />
            <Route element={<AuctionDetailLivePage />} path="auction/:id/live" />
            <Route element={<UserLayout />} path="user">
              <Route element={<Navigate replace to="/user/settings" />} index />
              <Route element={<UserSettingsPage />} path="settings" />
              <Route element={<UserTransactionPage />} path="transaction" />
              <Route element={<UserDepositPage />} path="topup" />
              <Route element={<UserFavoritePage />} path="favorite" />
              <Route element={<UserNotificationPage />} path="notification" />
              <Route element={<UserProxyBidPage />} path="proxy-bid" />
              <Route element={<p>page not found</p>} path="*" />
            </Route>
          </Route>
        </Route>
        {/* eslint-disable-next-line react/no-children-prop */}
        <Route element={<Suspense children={<Outlet />} fallback="..." />} path="/">
          <Route element={<RootLayout />}>
            <Route element={<HomePage />} index />
            <Route element={<UnitPage />} path="unit" />
            <Route element={<UnitDetailPage />} path="unit/:id" />
            <Route element={<UnitDetailInspectPage />} path="unit/:id/inspect" />
            <Route element={<AuctionPage />} path="auction" />
            <Route element={<AuctionDetailPage />} path="auction/:id" />
            <Route element={<ConsignmentSalePage />} path="consignment-sale" />
            <Route element={<ArticlePage />} path="article" />
            <Route element={<ArticleDetailPage />} path="article/:slug" />
            <Route element={<AboutPage />} path="about" />
            <Route element={<ProcedureLayout />} path="procedure">
              <Route element={<Navigate replace to="/procedure/live-event" />} index />
              <Route element={<ProcedurePage />} path=":slug" />
            </Route>
            <Route element={<PrivacyPage />} path="privacy" />
            <Route element={<TermsPage />} path="terms" />
            <Route element={<LocationPage />} path="location" />
            <Route element={<FAQPage />} path="faq" />
            <Route element={<ContactPage />} path="contact" />
            <Route element={<PrivacyNoticePage />} path="privacy-notice" />
            <Route element={<p>page not found</p>} path="*" />
          </Route>
        </Route>
      </Route>
    </Switch>
  );
}
