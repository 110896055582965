import { useState } from 'react';

export default function countryState() {
  const [countries, setCountries] = useState([]);

  return { countries, setCountries };
}

export const initialCountryState = {
  countries: [],
  setCountries: () => alert('unhandled function'),
};
