import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Page from 'routes/RouteList';
import useApp from './useApp';

function App() {
  const { pathname } = useLocation();
  const { checkCredential, scrollToTop, loading } = useApp();

  useEffect(() => {
    checkCredential();
    scrollToTop();
  }, [pathname, checkCredential, scrollToTop]);

  if (loading)
    return <div className="fixed inset-0 h-screen w-full bg-[rgba(45,_50,_29,_0.80)]">loading credential...</div>;

  return (
    <>
      <Page />
      <Toaster />
    </>
  );
}

export default App;
