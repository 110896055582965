import { useState } from 'react';

export default function modalState() {
  const [isOpen, setOpen] = useState(false);
  const [children, setChildren] = useState('');

  function openModal(reactNode) {
    setOpen(true);

    setChildren(reactNode);
  }

  function closeModal() {
    setOpen(false);

    setChildren('');
  }

  return { isOpen, openModal, closeModal, children };
}

export const initialModalState = {
  isOpen: false,
  openModal: () => alert('modalState: unhandled function'),
  closeModal: () => alert('modalState: unhandled function'),
  children: '',
};
