import { API_BASE_URL } from 'environment';

export async function getAllLocationAPI(searchParams) {
  var requestOptions = { method: 'GET', redirect: 'follow' };

  return fetch(`${API_BASE_URL}/api/location${searchParams ?? ''}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => err);
}
