import { useState } from 'react';

export default function bankState() {
  const [banks, setBanks] = useState([]);

  return { banks, setBanks };
}

export const initialBankState = {
  banks: [],
  setBanks: () => alert('unhandled function'),
};
