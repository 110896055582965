import { createContext } from 'react';
import authState, { initialAuthState } from 'context/state/authState';
import modalState, { initialModalState } from './state/modalState';
import alertState, { initialAlertState } from './state/alertState';
import cityState, { initialCityState } from './state/cityState';
import countryState, { initialCountryState } from './state/countryState';
import locationState, { initialLocationState } from './state/locationState';
import unitTypeState, { initialUnitTypeState } from './state/unitTypeState';
import bankState, { initialBankState } from './state/bankState';

const initialState = {
  auth: initialAuthState,
  modal: initialModalState,
  alert: initialAlertState,
  city: initialCityState,
  country: initialCountryState,
  location: initialLocationState,
  unitType: initialUnitTypeState,
  bank: initialBankState,
};

export const AppContext = createContext({ ...initialState });

const storage = () => ({
  auth: authState(),
  modal: modalState(),
  alert: alertState(),
  country: countryState(),
  city: cityState(),
  location: locationState(),
  unitType: unitTypeState(),
  bank: bankState(),
});

export default storage;
