import { Suspense, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useAppState from 'context/useAppState';

export default function AuthRoute() {
  const { isAuthenticated } = useAppState((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const redirectLink = searchParams.get('continue');

  useEffect(() => {
    if (!isAuthenticated) return;

    if (isAuthenticated && redirectLink) return navigate(redirectLink, { replace: true, state: location });

    navigate('/', { replace: true, state: location });
  }, [redirectLink, navigate, location, isAuthenticated]);

  return (
    <Suspense fallback="...">
      <Outlet />
    </Suspense>
  );
}
