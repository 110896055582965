import { API_BASE_URL } from 'environment';

export async function registerAPI(name, email, password, password_confirmation) {
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('lang', 'id');

  var raw = JSON.stringify({ email, password, password_confirmation, name });

  var requestOptions = { method: 'POST', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${API_BASE_URL}/api/register`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => err);
}
export async function registerWithGoogleAPI(id, full_name, email, avatar) {
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('lang', 'id');

  var raw = JSON.stringify({ id, full_name, email, avatar });

  var requestOptions = { method: 'POST', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${API_BASE_URL}/api/register/social/google`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => err);
}

export async function reverifyEmailAPI(email) {
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('lang', 'id');

  var raw = JSON.stringify({ email });

  var requestOptions = { method: 'POST', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${API_BASE_URL}/api/reverify_email`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => err);
}

export async function loginAPI(username, password) {
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('lang', 'id');

  var raw = JSON.stringify({
    email: username,
    password: password,
  });

  var requestOptions = { method: 'POST', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${API_BASE_URL}/api/login`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => err);
}

export async function loginWithGoogleAPI(id, email, full_name, avatar) {
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  var urlencoded = new URLSearchParams();
  urlencoded.append('id', id);
  urlencoded.append('email', email);
  urlencoded.append('full_name', full_name);
  urlencoded.append('avatar', avatar);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
  };

  return fetch(`${API_BASE_URL}/api/login/social/google`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function getUserDataAPI(token) {
  var myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  var requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

  return fetch(`${API_BASE_URL}/api/account/user`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => err);
}

export async function logoutAPI(token) {
  var myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  var requestOptions = { method: 'POST', headers: myHeaders, redirect: 'follow' };

  return fetch(`${API_BASE_URL}/api/logout`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function forgotPasswordAPI(email) {
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('lang', 'id');

  var raw = JSON.stringify({ email });

  var requestOptions = { method: 'POST', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${API_BASE_URL}/api/password/send`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => err);
}

export async function resetPasswordAPI(token, email, password, password_confirmation) {
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  var urlencoded = new URLSearchParams();
  urlencoded.append('token', token);
  urlencoded.append('email', email);
  urlencoded.append('password', password);
  urlencoded.append('password_confirmation', password_confirmation);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
  };

  return fetch(`${API_BASE_URL}/api/password/reset`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => err);
}
