import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import useAppState from 'context/useAppState';

export default function AlertWrapper() {
  const { isOpen, closeAlert, children } = useAppState((state) => state.alert);

  return (
    <Transition appear as={Fragment} show={isOpen}>
      <Dialog as="div" className="relative z-10" onClose={closeAlert}>
        <div className="fixed bottom-10 left-1/2 h-max w-max -translate-x-1/2">
          <Transition.Child
            as="div"
            enter="ease-out duration-200"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {children}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
