import { useCallback, useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { CRYPTO_SECRET } from 'environment';
import useAppState from 'context/useAppState';
import { getUserDataAPI } from 'services/api/auth';
import useFetch from 'hooks/useFetch';
import { getAllCountryAPI } from 'services/api/country';
import { getAllLocationAPI } from 'services/api/location';
import { getAllCityAPI } from 'services/api/city';
import { getAllUnitTypeAPI } from 'services/api/unit';
import { getAllBankAPI } from 'services/api/bank';
import { toast } from 'react-hot-toast';

export default function useApp() {
  const [loading, setLoading] = useState(true);

  const { auth, country, city, location, unitType, bank } = useAppState();

  const { isAuthenticated, clearAuthState, storeAuthState } = auth;

  const fetchAPI = useFetch();

  function logoutAction() {
    localStorage.removeItem('credential');
    clearAuthState();
  }

  async function getUserData(token) {
    try {
      const option = { withLoadingScreen: false, withToast: false };

      const res = await fetchAPI(() => getUserDataAPI(token), option);

      return [null, res];
    } catch (error) {
      logoutAction();
      return ['Invalid Credential'];
    }
  }

  const checkCredential = useCallback(async () => {
    let credential = { token: '', expiry: 0 };

    const credentialLocal = localStorage.getItem('credential');

    if (credentialLocal) {
      const bytes = CryptoJS.AES.decrypt(credentialLocal, `${CRYPTO_SECRET}`);

      let decryptedCredential;

      try {
        decryptedCredential = bytes.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        logoutAction();
      }

      if (!decryptedCredential) return logoutAction();

      const newCredential = JSON.parse(decryptedCredential);

      credential.token = newCredential.token;

      credential.expiry = Number(newCredential.expiry);
    }

    // unauthenticated
    if (Date.now() > credential.expiry) {
      logoutAction();

      setLoading(false);

      return;
    }

    // authenticated but state already stored
    if (isAuthenticated) return;

    // authenticated and store the state
    const [error, res] = await getUserData(credential.token).finally(() => setLoading(false));

    if (error) return toast.error(error);

    storeAuthState(credential.token, credential.expiry, res.data);
  }, [isAuthenticated]);

  const scrollToTop = useCallback(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
  }, []);

  const getInitialData = useCallback(async () => {
    const option = { withLoadingScreen: false, withToast: false };

    const iteration = [
      fetchAPI(() => getAllCountryAPI(), option),
      fetchAPI(() => getAllCityAPI(), option),
      fetchAPI(() => getAllLocationAPI(), option),
      fetchAPI(() => getAllUnitTypeAPI(), option),
      fetchAPI(() => getAllBankAPI(), option),
    ];

    const [countries, cities, locations, unitTypes, banks] = await Promise.all(iteration);

    country.setCountries(countries.data.data);
    city.setCities(cities.data.data);
    location.setLocations(locations.data.data);
    unitType.setUnitTypes(unitTypes.data.data);
    bank.setBanks(banks.data.data);
  }, []);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  // auto log out

  let timer;

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
      toast('Sesi anda telah berakhir', { icon: '⚠️' });
    }, 600000 * 6); // 600000ms = 10min. You can change the time.
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    if (!isAuthenticated) return;

    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, [isAuthenticated]);

  return { checkCredential, scrollToTop, getUserData, loading, getInitialData };
}

const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];
