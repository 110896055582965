import { useState } from 'react';

export default function cityState() {
  const [cities, setCities] = useState([]);

  return { cities, setCities };
}

export const initialCityState = {
  cities: [],
  setCities: () => alert('unhandled function'),
};
