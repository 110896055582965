import AlertWrapper from 'components/AlertWrapper';
import ModalWrapper from 'components/ModalWrapper';
import storage, { AppContext } from 'context/store';

export default function Provider({ children }) {
  const value = storage();

  return (
    <AppContext.Provider value={{ ...value }}>
      <ModalWrapper />
      <AlertWrapper />
      {children}
    </AppContext.Provider>
  );
}
