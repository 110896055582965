import { API_BASE_URL } from 'environment';

export async function getAllBankAPI() {
  var requestOptions = { method: 'GET', redirect: 'follow' };

  return fetch(`${API_BASE_URL}/api/bank`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((err) => err);
}
