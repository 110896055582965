import { useState } from 'react';

export default function locationState() {
  const [locations, setLocations] = useState([]);

  return { locations, setLocations };
}

export const initialLocationState = {
  locations: [],
  setLocations: () => alert('unhandled function'),
};
