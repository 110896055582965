import { useState } from 'react';

export default function unitType() {
  const [unitTypes, setUnitTypes] = useState([]);

  return { unitTypes, setUnitTypes };
}

export const initialUnitTypeState = {
  unitTypes: [],
  setUnitTypes: () => alert('unhandled function'),
};
