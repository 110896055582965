import { useState } from 'react';

export default function authState() {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [token, setToken] = useState('');
  const [expiry, setExpiry] = useState(0);
  const [userData, setUserData] = useState(initialUserData);

  async function storeAuthState(token, expiry, _userData) {
    setAuthenticated(true);
    setToken(token);
    setExpiry(expiry);
    setUserData(_userData);
  }

  function clearAuthState() {
    setAuthenticated(false);
    setToken('');
    setExpiry(0);
    setUserData({
      id: 0,
      is_master_auction: null,
      name: '',
      email: '',
      phone: null,
      is_active: false,
      email_verified_at: '',
      created_at: '',
      updated_at: '',
      deleted_at: null,
      image_filepath: null,
      image_filename: null,
      image_url: '',
      buyer: { is_special_buyer: false },
    });
  }

  return { isAuthenticated, token, expiry, storeAuthState, clearAuthState, userData, setUserData };
}

const initialUserData = {
  id: 0,
  is_master_auction: null,
  name: '',
  email: '',
  phone: null,
  is_active: false,
  email_verified_at: '',
  created_at: '',
  updated_at: '',
  deleted_at: null,
  image_filepath: null,
  image_filename: null,
  image_url: '',
  buyer: { is_special_buyer: false },
};

export const initialAuthState = {
  isAuthenticated: false,
  token: '',
  expiry: 0,
  userData: initialUserData,
  storeAuthState: () => alert('unhandled function'),
  clearAuthState: () => alert('unhandled function'),
};
